import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './app';
import * as Sentry from "@sentry/react";

import './index.css';

Sentry.init({
    dsn: "https://dc42110697278584ce54e9d64580e583@o4506822230999040.ingest.us.sentry.io/4507317328740352",
    enabled: false, // window.location.hostname === 'connect.campusthreads.co',
    tracePropagationTargets: ["localhost", /^(https:\/\/)?([\w-]+\.)*campusthreads\.co/],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllInputs: false,
            maskAllText: false,
            networkCaptureBodies: true,
            networkRequestHeaders: [
                "Authorization",
                "Cache-Control",
                "Content-Type",
                "User-Agent",
                "Host",
                "Origin",
                "Referer",
                "X-Requested-With",
            ],
            networkResponseHeaders: [
                "Server",
                "Date",
                "Content-Type",
                "Content-Length",
                "Referrer-Policy",
                "X-Content-Type-Options",
            ],
            networkDetailAllowUrls: [
                window.location.origin,
                /^(https:\/\/)?([\w-]+\.)*campusthreads\.co/,
            ],
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: window.location.hostname === 'connect.campusthreads.co' ? 1.0 : 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <BrowserRouter>
            <Suspense>
                <App />
            </Suspense>
        </BrowserRouter>
    </HelmetProvider>
);
