import DOMPurify from "dompurify";

export function textWithClickableLinks(text) {
    if (!text || typeof text !== 'string') {
        return text;
    }
    const urlPattern = /(https:\/\/|http:\/\/|www\.)?[a-zA-Z0-9\-]{2,}(\.[a-zA-Z0-9\-]{2,}){1,}(\/[a-zA-Z0-9\-._~:\/?#[\]@!$&'()*+,;=%]*)?/gi;
    const formatted = text.replace(urlPattern, (url) => {
        const href = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
        return `<a style="text-decoration: underline; color: #2a9d8f;" href="${href}" target="_blank">${url}</a>`;
    });

    return DOMPurify.sanitize(formatted);
}
