export const isAmbassadorAvailable = (ambassador) => {
    if (!ambassador) {
        return false;
    }

    const isAlwaysAvailable = ambassador.alwaysAvailableForChats;
    const availability = ambassador.chatsAvailability;
    const timezone = ambassador.availabilityTimezone;
    // Availability is an array of items where each one 
    // is expressed as a weekday string (weekDay), a start time (start) and an end time (end)
    // in the format "HH:MM" (24-hour format) in the specified timezone.

    if (isAlwaysAvailable) {
        return true;
    }

    if (!availability || availability.length === 0) {
        return false;
    }

    // First get the current time in the specified timezone
    const currentTime = new Date().toLocaleString('en-US', { timeZone: timezone });
    // Get what the day of the week that current time is
    const currentWeekDayIndex = new Date(currentTime).getDay();
    const currentWeekDayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][currentWeekDayIndex];
    // Get the current time in the format "HH:MM:SS"
    const currentTimeFormatted = new Date(currentTime).toLocaleTimeString('en-US',
        {
            hour12: false, // Use 24-hour format
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

    // Check if any of the availabilities match (same weekday and current time is between start and end)
    return availability.some((availabilityItem) => {
        return availabilityItem.weekDay === currentWeekDayString &&
            availabilityItem.startTime <= currentTimeFormatted &&
            availabilityItem.endTime >= currentTimeFormatted;
    });
};