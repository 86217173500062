import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { CollectionSearch } from 'src/components/collection-search';
import { countries, canadianProvinces } from 'src/utils/locations';
import { useAuth } from 'src/hooks/use-auth';
import { setDoc, collection, doc } from "firebase/firestore";
import { analytics, db } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';

// TODO populate this the same way we do tags
const programs = [
    "Undecided / No Program",
    "Applied Social Sciences (BA)",
    "Applied Social Sciences - Social Work (BA)",
    "Art (BA)",
    "Biochemistry (BSc)",
    "Biology (BSc)",
    "Business (BA)",
    "Accounting (BBA)",
    "Management (BBA)",
    "Marketing (BBA)",
    "Chemistry (BSc)",
    "Bachelor of Education",
    "English Literature (BA)",
    "English Writing (BA)",
    "Environmental Science (BSc)",
    "Environmental Studies (BA)",
    "Health Sciences (BSc)",
    "Health Sciences Pre-Medicine (BSc)",
    "History (BA)",
    "Kinesiology (B.Kin)",
    "Kinesiology (B.Sc)",
    "Mathematics (B.Sc)",
    "Media Production (BA)",
    "Media Production (MCoMS)",
    "Church Music Ministry (BA)",
    "Music in Worship (BA)",
    "Music Performance (BA)",
    "Philosophy (BA)",
    "Physical Education (BA)",
    "Politics and International Studies (BA)",
    "International Development (BA)",
    "International Relations (BA)",
    "Psychology (BA)",
    "Biblical and Theological Studies (BA)",
    "Mission & Ministry (BA)",
    "Mission & Ministry - Urban Ministry (BA)",
    "Mission & Ministry - Youth Ministry (BA)",
];




const getRegistrationMonths = () => {
    const registrationMonths = [1, 5, 9]; // January, May, September
    const currDate = new Date();
    let currMonth = currDate.getMonth() + 1; // getMonth() returns month index starting from 0
    let currYear = currDate.getFullYear();

    // Find the next registration month
    while (!registrationMonths.includes(currMonth)) {
        currMonth++;
        if (currMonth > 12) {
            currMonth = 1;
            currYear++;
        }
    }

    // Generate the next 5 registration months
    const options = [];
    for (let i = 0; i < 5; i++) {
        options.push(`${getMonthName(currMonth)} ${currYear}`);
        currMonth++;
        if (currMonth > 12) {
            currMonth = 1;
            currYear++;
        }
        while (!registrationMonths.includes(currMonth)) {
            currMonth++;
            if (currMonth > 12) {
                currMonth = 1;
                currYear++;
            }
        }
    }

    return options;
};

const getMonthName = (monthIndex) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[monthIndex - 1];
};

export function PreMessageFormContent({ originalUser, activeSchool, user, isSettingsPage = false }) {
    const [alreadyApplied, setAlreadyApplied] = useState(originalUser.alreadyApplied || null);
    const [plannedEnrollment, setPlannedEnrollment] = useState(originalUser.plannedEnrollment || null);
    const [selectedPrograms, setSelectedPrograms] = useState(originalUser.selectedPrograms || []);
    const [selectedCountryOrProvince, setSelectedCountryOrProvince] = useState(originalUser.selectedCountryOrProvince || null);
    const schoolName = activeSchool?.name;

    const firstRender = useRef(true);

    const countriesAndProvinces = [
        ...countries.map((country) => ({ type: "International", value: country, id: country })),
        ...canadianProvinces.map((province) => ({ type: "Canada", value: province, id: province })),
    ];

    const registrationMonths = [
        ...getRegistrationMonths(),
        "Not sure yet",
    ];

    // Update the school in Firestore everytime the user changes the form
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        // Update the user within the prospects collection of the schools collection in Firestore
        const prospectDoc = doc(db, `schools/${activeSchool.id}/prospects/${user.id}`);

        setDoc(prospectDoc, {
            alreadyApplied: alreadyApplied,
            plannedEnrollment: plannedEnrollment,
            selectedPrograms: selectedPrograms,
            selectedCountryOrProvince: selectedCountryOrProvince,
            created: user.created,
        }, { merge: true });
    }, [alreadyApplied, plannedEnrollment, selectedPrograms, selectedCountryOrProvince, originalUser]);

    return (
        <Box sx={{ textAlign: isSettingsPage ? "left" : "-webkit-center", ml: isSettingsPage ? "20px" : "unset", maxWidth: isSettingsPage ? "450px" : "unset" }}>
            {
                (!originalUser.alreadyApplied || isSettingsPage) && (
                    <>
                        <Typography mt={2} fontWeight={isSettingsPage ? 500 : 700}>
                            Have you already applied {schoolName ? `to ${schoolName}` : ""}?
                        </Typography>
                        <Stack direction="row" spacing={2} mt={2} justifyContent={"center"}>
                            <Button sx={{ flexGrow: 1 }} variant={alreadyApplied === "Yes" ? "contained" : "outlined"} onClick={() => setAlreadyApplied("Yes")}>Yes</Button>
                            <Button sx={{ flexGrow: 1 }} variant={alreadyApplied === "No" ? "contained" : "outlined"} onClick={() => setAlreadyApplied("No")}>No</Button>
                        </Stack>
                    </>
                )
            }
            {
                (!originalUser.plannedEnrollment || isSettingsPage) && (
                    <>
                        <Typography mt={3} fontWeight={isSettingsPage ? 500 : 700}>
                            When are you planning to enroll?
                        </Typography>
                        <Grid container spacing={2} mt={0} justifyContent={"center"}>
                            {registrationMonths.map((month) => (
                                <Grid item key={month}>
                                    <Button variant={plannedEnrollment === month ? "contained" : "outlined"} onClick={() => setPlannedEnrollment(month)}>{month}</Button>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )
            }
            {
                (!originalUser.selectedPrograms?.length || isSettingsPage) && (
                    <Box pr={2}>
                        <Typography mt={3} mb={3} fontWeight={isSettingsPage ? 500 : 700}>
                            Which programs are you interested in?
                        </Typography>
                        <CollectionSearch initialFilters={{ tags: !!originalUser.selectedPrograms ? originalUser.selectedPrograms.map((originalProgram) => ({ type: "Program", value: originalProgram, id: originalProgram })) : [] }} options={programs.map((program) => ({ type: "Program", value: program, id: program }))} onFiltersChange={(filters) => setSelectedPrograms(filters.tags?.map((program) => program.value))} label={`Type or select program`} />
                    </Box>
                )
            }
            {
                !originalUser.selectedCountryOrProvince && !isSettingsPage && (
                    <>
                        <Typography mt={3} mb={3} fontWeight={isSettingsPage ? 400 : 700}>
                            Which country/province are you from?
                        </Typography>
                        <CollectionSearch initialFilters={{ tags: { type: "International", value: "🌍 Not Specified", id: "Global" } }} options={countriesAndProvinces} onFiltersChange={(filters) => setSelectedCountryOrProvince(filters.tags.value)} label={`Type or select country / region`} multiple={false} />
                    </>
                )
            }
        </Box>
    );
};

export default function ChatPreMessageForm({ ambassadorName, activeSchool, open, onClose, onSkip }) {
    const { user, schoolSpecificUserInfo } = useAuth();
    const [originalUser, setOriginalUser] = useState(schoolSpecificUserInfo ?? {
        aiChat: null,
        alreadyApplied: null,
        plannedEnrollment: null,
        selectedPrograms: [],
        selectedCountryOrProvince: null,
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();

                    onClose();
                },
            }}
        >
            <DialogTitle variant='h3' >Few quick questions so {ambassadorName ?? "the ambassador"} can assist you better</DialogTitle>
            <DialogContent sx={{ textAlign: "-webkit-center" }}>
                <PreMessageFormContent originalUser={originalUser} activeSchool={activeSchool} user={user} onClose={onClose} onSkip={onSkip} />
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ color: "gray" }}
                    onClick={() => {
                        logAnalyticsEvent('pre_message_form_skipped', { school: activeSchool.id });
                        onSkip();
                        onClose();
                    }}
                >
                    Skip for now
                </Button>
                <Button
                    type="submit"
                    size='large'
                    onClick={() => {
                        onSkip();
                        onClose();
                    }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}