import { Box, Button, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssistantIcon from '@mui/icons-material/Assistant';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import PropTypes from 'prop-types';
import MessageDotsSquare from '@untitled-ui/icons-react/build/esm/MessageDotsSquare';


export const ChatBlank = ({ onTabChange, onMatchAmbassador, onAiChat }) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            paddingBottom: 20,
            marginTop: 10,
        }}
    >
        <Typography
            color="text.secondary"
            sx={{ my: 2 }}
            variant="subtitle1"
        >
            Don't know where to start?
        </Typography>
        <Box
            // component="img"
            // src="/assets/errors/error-404.png"
            sx={{
                height: 'auto',
                maxWidth: 120
            }}
        >
            <SvgIcon sx={{ height: 120, width: 120, color: "#b4b4b4", mb: 2.5 }}>
                <MessageDotsSquare />
            </SvgIcon>
        </Box>
        <Button
            onClick={() => onTabChange({
                whichTab: 'students',
            })}
            startIcon={(
                <SvgIcon>
                    <ConnectWithoutContactIcon />
                </SvgIcon>
            )}
            variant="contained"
            sx={{ backgroundColor: 'black' }}
        >
            Connect with an Ambassador
        </Button>
        <Typography
            color="text.secondary"
            sx={{ my: 1 }}
            variant="subtitle1"
        >
            OR
        </Typography>
        <Button
            onClick={() => onAiChat()}
            startIcon={(
                <SvgIcon>
                    <AssistantIcon />
                </SvgIcon>
            )}
            variant="contained"
            sx={{ backgroundColor: 'black' }}
        >
            Get Instant Answers from our AI Bot
        </Button>
    </Box>
);

ChatBlank.propTypes = {
    onTabChange: PropTypes.func,
    onAiChat: PropTypes.func,
    onMatchAmbassador: PropTypes.func,
};

