import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    browserLocalPersistence,
    setPersistence,
    signInWithRedirect,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    connectAuthEmulator,
    deleteUser,
    reauthenticateWithPopup,
    signInWithEmailLink,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
} from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { setUserId, setUserProperties } from "firebase/analytics";
import { logAnalyticsEvent } from 'src/utils/logging';
import { firebaseApp, analytics, db } from 'src/libs/firebase';
import { getDoc, setDoc } from "firebase/firestore";
import { Issuer } from 'src/utils/auth';
import { doc, onSnapshot } from "firebase/firestore";
import useSchool from 'src/hooks/use-school';
import * as Sentry from "@sentry/react";

const auth = getAuth(firebaseApp);

// Get the Functions instance
const functions = getFunctions(firebaseApp);

// Get a reference to the registerNewUser function

if (window.location.hostname === 'localhost') {
    // connectAuthEmulator(auth, 'http://localhost:9099');
    // connectFunctionsEmulator(functions, 'localhost', 5001);
}

var ActionType;
(function (ActionType) {
    ActionType['AUTH_STATE_CHANGED'] = 'AUTH_STATE_CHANGED';
})(ActionType || (ActionType = {}));

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    school: null,
    schoolSpecificUserInfo: null,
    aiChatHistory: [],
    aiChatLoaded: false,
};

const reducer = (state, action) => {
    if (action.type === 'AUTH_STATE_CHANGED') {
        const newState = { ...state, isInitialized: true };

        for (const key in action.payload) {
            if (action.payload[key] !== undefined) {
                newState[key] = action.payload[key];
            }
        }

        return newState;
    }

    return state;
};

export const AuthContext = createContext({
    ...initialState,
    issuer: Issuer.Firebase,
    createUserWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailLink: () => Promise.resolve(),
    sendSignInLinkToEmail: () => Promise.resolve(),
    isSignInWithEmailLink: () => false,
    signInWithGoogle: () => Promise.resolve(),
    signOut: () => Promise.resolve()
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const { school: activeSchool, slug: schoolSlug, parentURL, isIframe } = useSchool();

    const dispatchUser = (user, userData) => {
        dispatch({
            type: ActionType.AUTH_STATE_CHANGED,
            payload: {
                isAuthenticated: true,
                user: {
                    id: user.uid,
                    avatar: user.photoURL || undefined,
                    email: user.email || '',
                    name: userData.name || user.displayName || user.name || `${user.firstName} ${user.lastName}` || "",
                    plan: userData.plan || 'Premium',
                    blockedBy: userData.blockedBy || [],
                    notificationPreference: userData.notificationPreference || null,
                    phone: userData.phone || null,
                    agreedToMarketing: userData.agreedToMarketing || null,
                    isParentRelative: userData.isParentRelative || false,
                    docLoaded: true,
                    created: userData.created || null,
                }
            }
        });
    };

    const handleAuthStateChanged = useCallback((user) => {
        if (user) {
            Sentry.setUser({ id: user.uid, email: user.email });
            setUserId(analytics, user.uid);
            setUserProperties(analytics, { email: user.email, isProspect: true, type: "prospect" });

            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: true,
                    user: {
                        id: user.uid,
                        uid: user.uid,
                        photoURL: user.photoURL || undefined,
                        avatar: user.photoURL || undefined,
                        email: user.email,
                        name: user.displayName || user.email,
                        displayName: user.displayName || user.email,
                        docLoaded: false,
                    },
                }
            });

            const userRef = doc(db, "users", user.uid);
            const ambassadorRef = doc(db, "users", `ambassador_${user.uid}`);
            const adminRef = doc(db, "users", `admin_${user.uid}`);
            const unsubscribe = onSnapshot(userRef, (userDoc) => {
                if (userDoc.exists()) {
                    const userData = userDoc.data();

                    dispatchUser(user, userData);
                }
            }, (error) => {
                console.error(error);
                logAnalyticsEvent('error_snapshot_auth_user', {
                    description: error.message,
                    school: activeSchool?.id || schoolSlug,
                    user: user.uid,
                });
            });

            // TODO Remember to unsubscribe from the snapshot when component unmounts
            return () => unsubscribe();
        } else {
            dispatch({
                type: ActionType.AUTH_STATE_CHANGED,
                payload: {
                    isAuthenticated: false,
                    user: null,
                    school: null,
                    schoolSpecificUserInfo: null,
                    aiChatHistory: [],
                    aiChatLoaded: false,
                }
            });
        }
    }, [dispatch, db, activeSchool]);

    useEffect(() => onAuthStateChanged(auth, handleAuthStateChanged),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    useEffect(() => {
        let unsubscribeSchool = null;
        if (activeSchool && state.user) {
            const prospectDoc = doc(db, `schools/${activeSchool.id}/prospects/${state.user?.id}`);
            unsubscribeSchool = onSnapshot(prospectDoc, (prospectDoc) => {
                let schoolSpecificUserInfo = {
                    aiChat: null,
                    alreadyApplied: null,
                    plannedEnrollment: null,
                    selectedPrograms: [],
                    selectedCountryOrProvince: null,
                };

                if (prospectDoc.exists()) {
                    schoolSpecificUserInfo = prospectDoc.data();
                }

                if (!schoolSpecificUserInfo.aiChat) {
                    const createAIChatFunction = httpsCallable(functions, 'createAIChat');
                    createAIChatFunction({ schoolId: activeSchool.id, prospectId: state.user?.id });
                } else {
                    // Get the chat messages
                    const fetchAIChatFunction = httpsCallable(functions, 'fetchAIChat');
                    fetchAIChatFunction({ schoolId: activeSchool.id }).then((result) => {
                        console.log("fetchAIChatFunction result", result);

                        dispatch({
                            type: ActionType.AUTH_STATE_CHANGED,
                            payload: {
                                aiChatHistory: result.data,
                                aiChatLoaded: true,
                            }
                        });
                    }).catch((error) => {
                        console.error(error);
                    });
                }

                dispatch({
                    type: ActionType.AUTH_STATE_CHANGED,
                    payload: {
                        isAuthenticated: true,
                        school: activeSchool,
                        schoolSpecificUserInfo,
                    }
                });
            }, (error) => {
                console.error(error);
                logAnalyticsEvent('error_snapshot_auth_school_prospect', {
                    description: error.message,
                    school: activeSchool?.id || schoolSlug,
                    user: state.user?.id,
                });
            });
        }

        return () => {
            if (unsubscribeSchool) {
                unsubscribeSchool();
            }
        }
    }, [activeSchool, state.user]);

    const _signInWithEmailAndPassword = useCallback(async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
    }, []);

    const signInWithGoogle = useCallback(async () => {
        logAnalyticsEvent('login', { method: 'google', school: activeSchool?.id || schoolSlug });
        const provider = new GoogleAuthProvider();

        // Save the current URL before initiating the sign-in process
        const currentURL = window.location.href;

        await setPersistence(auth, browserLocalPersistence)
            .then(() => {
                return signInWithPopup(auth, provider);
            })
            .then(() => {
                // After the sign-in process is complete, redirect back to the saved URL
                window.location.href = currentURL;
            })
            .catch((error) => {
                // Handle Errors here.
                console.error(error);
                logAnalyticsEvent('login_error', {
                    school: activeSchool?.id || schoolSlug,
                    error: error.code,
                });
            });
    }, [activeSchool, schoolSlug]);

    const _createUserWithEmailAndPassword = useCallback(async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password);
    }, []);

    const _sendSignInLinkToEmail = useCallback(async (email) => {
        logAnalyticsEvent('login', { method: 'email_link', school: activeSchool?.id || schoolSlug });
        // const parentURLWithoutQuery = parentURL.split('?')[0];
        // const currentURLWithoutQuery = window.location.href.split('?')[0];

        const actionCodeSettings = {
            url: isIframe ? parentURL : window.location.href,
            handleCodeInApp: true,
        };

        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
    }, [activeSchool, schoolSlug, parentURL, isIframe]);

    const _signInWithEmailLink = useCallback(async (email) => {
        const currentURL = parentURL || window.location.href;
        await signInWithEmailLink(auth, email, currentURL);
        // Reset query params so that we do not mistake it for email verification URL
        window.history.replaceState({}, document.title, window.location.pathname);
    }, [parentURL]);

    const _isSignInWithEmailLink = useCallback(() => {
        const currentURL = parentURL || window.location.href;
        return isSignInWithEmailLink(auth, currentURL);
    }, [parentURL]);

    const _signOut = useCallback(async () => {
        logAnalyticsEvent('signout', {
            school: activeSchool?.id || schoolSlug,
        });
        window.localStorage.removeItem('emailForSignIn');
        await signOut(auth);
    }, [activeSchool, schoolSlug]);

    const _deleteUser = useCallback(async () => {
        try {
            await deleteUser(auth.currentUser);
        } catch (error) {
            logAnalyticsEvent('delete_user_error', {
                school: activeSchool?.id || schoolSlug,
                error: error?.code,
            });
            if (error.code === 'auth/requires-recent-login') {
                const deleteUserFunction = httpsCallable(functions, 'deleteUser');
                await deleteUserFunction({ uid: auth.currentUser.uid });
            } else {
                // Handle other errors
                // ...
            }
        }

        logAnalyticsEvent('delete_user', {
            school: activeSchool?.id || schoolSlug,
        });

        window.localStorage.removeItem('emailForSignIn');

        dispatch({
            type: ActionType.AUTH_STATE_CHANGED,
            payload: {
                isAuthenticated: false,
            }
        });
    }, [activeSchool, schoolSlug, auth.currentUser]);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                issuer: Issuer.Firebase,
                createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
                signInWithEmailAndPassword: _signInWithEmailAndPassword,
                signInWithEmailLink: _signInWithEmailLink,
                sendSignInLinkToEmail: _sendSignInLinkToEmail,
                isSignInWithEmailLink: _isSignInWithEmailLink,
                signInWithGoogle,
                signOut: _signOut,
                deleteUser: _deleteUser,
                dispatchUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
