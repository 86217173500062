import { analytics } from "src/libs/firebase";
import { logEvent } from "firebase/analytics";
import { captureEvent, captureException } from "@sentry/react";

export function logAnalyticsEvent(eventName, eventParams = {}) {
    if (analytics) {
        if (eventParams) {
            logEvent(analytics, eventName, eventParams);
        } else {
            logEvent(analytics, eventName);
        }
    }

    if (eventName.startsWith("error")) {
        captureException(new Error(eventName), {
            eventName,
            eventParams
        });

        console.error(eventName, eventParams);
    } else {
        captureEvent({
            eventName,
            eventParams,
        });
    }
}


