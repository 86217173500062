import PropTypes from "prop-types";
import { Avatar as MUIAvatar } from "@mui/material";
import Avatar from 'avataaars';

const FailSafeAvatar = ({ firstName = null, profilePicURL = null, cartoonOptions = null, sz = 40, sx }) => {
    const alt = firstName ?? "AMA!";

    const basePhotoURL = profilePicURL ?? "https://ionicframework.com/docs/img/demos/avatar.svg";
    const photoName = basePhotoURL.substring(basePhotoURL.lastIndexOf('/') + 1);
    const photoURLPrefix = basePhotoURL.substring(0, basePhotoURL.lastIndexOf('/') + 1);

    const thumbnailURL = `${photoURLPrefix}thumbnail_${photoName}`;
    const smallURL = `${photoURLPrefix}small_${photoName}`;
    const mediumURL = `${photoURLPrefix}medium_${photoName}`;
    const largeURL = `${photoURLPrefix}large_${photoName}`;

    const srcURL = sz <= 50 ? thumbnailURL : sz <= 100 ? smallURL : sz <= 200 ? mediumURL : largeURL;

    return (
        <MUIAvatar
            alt={alt}
            src={srcURL}
            sx={{ width: `${sz}px`, height: `${sz}px`, ...sx }}
        >
            <MUIAvatar
                alt={alt}
                src={!!cartoonOptions && basePhotoURL?.includes("ionicframework.com") ? null : basePhotoURL}
                sx={{ width: `${sz}px`, height: `${sz}px`, border: "none !important", ...sx }}
            >
                {
                    !!cartoonOptions ? (
                        <Avatar
                            avatarStyle="Transparent"
                            clotheType="CollarSweater"
                            clotheColor="Heather"
                            eyeType="Default"
                            eyebrowType="Default"
                            mouthType="Smile"
                            {...cartoonOptions}
                            facialHairColor={cartoonOptions.hairColor || "Black"}
                            style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                            }}
                        />
                    ) : (
                        <h5>{alt}</h5>
                    )
                }
            </MUIAvatar>
        </MUIAvatar>
    );
};

FailSafeAvatar.propTypes = {
    firstName: PropTypes.string,
    profilePicURL: PropTypes.string,
    sz: PropTypes.number,
};

export default FailSafeAvatar;
