import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Attachment01Icon from '@untitled-ui/icons-react/build/esm/Attachment01';
import Camera01Icon from '@untitled-ui/icons-react/build/esm/Camera01';
import Send01Icon from '@untitled-ui/icons-react/build/esm/Send01';
import { Avatar, Box, IconButton, OutlinedInput, Stack, SvgIcon, Tooltip } from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useAuth } from 'src/hooks/use-auth';

export const ChatMessageAdd = (props) => {
    const { onMessageChange, body, sendDisabled, disabled, onSend, ...other } = props;
    // const user = useMockedUser();
    const user = useAuth();
    const fileInputRef = useRef(null);

    const handleAttach = useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    const handleSend = useCallback(() => {
        if (!body) {
            return;
        }

        onSend?.(body);
    }, [body, onSend]);

    const handleKeyUp = useCallback((event) => {
        if (event.code === 'Enter') {
            handleSend();
        }
    }, [handleSend]);

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
                px: 3,
                py: 1
            }}
            {...other}>
            {
                !!user.avatar && (
                    <Avatar
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'inline'
                            }
                        }}
                        src={user.avatar}
                    />
                )
            }
            <OutlinedInput
                disabled={disabled}
                fullWidth
                onChange={onMessageChange}
                onKeyUp={handleKeyUp}
                placeholder="Leave a message"
                size="small"
                value={body}
            />
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    m: -2,
                    ml: 2
                }}
            >
                <Tooltip title="Send">
                    <Box sx={{ m: 1 }}>
                        <IconButton
                            color="primary"
                            disabled={!body || disabled || sendDisabled}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                                '&:hover': {
                                    backgroundColor: 'primary.dark'
                                }
                            }}
                            onClick={handleSend}
                        >
                            <SvgIcon>
                                <Send01Icon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                </Tooltip>
                {/* <Tooltip title="Attach photo">
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'inline-flex'
              },
              m: 1
            }}
          >
            <IconButton
              disabled={disabled}
              edge="end"
              onClick={handleAttach}
            >
              <SvgIcon>
                <Camera01Icon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title="Attach file">
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'inline-flex'
              },
              m: 1
            }}
          >
            <IconButton
              disabled={disabled}
              edge="end"
              onClick={handleAttach}
            >
              <SvgIcon>
                <Attachment01Icon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Tooltip> */}
            </Box>
            <input
                hidden
                ref={fileInputRef}
                type="file"
            />
        </Stack>
    );
};

ChatMessageAdd.propTypes = {
    sendDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    onSend: PropTypes.func,
    onMessageChange: PropTypes.func,
};

ChatMessageAdd.defaultProps = {
    disabled: false,
    sendDisabled: false,
};
