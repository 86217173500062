import { common } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { error, info, neutral, success, warning } from '../colors';
import { getPrimary } from '../utils';
import chroma from 'chroma-js';

function generatePalette(mainColor) {
    const dark = chroma(mainColor).darken().hex();
    const darkest = chroma(mainColor).darken(2).hex();
    const light = chroma(mainColor).brighten().hex();
    const lightest = chroma(mainColor).brighten(2).hex();
    const contrastText = chroma(mainColor).luminance() > 0.5 ? '#000000' : '#FFFFFF';

    return {
        lightest,
        light,
        main: mainColor,
        dark,
        darkest,
        contrastText,
    };
}

function isHexColor(hex) {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexColorRegex.test(hex);
}

export const createPalette = (config) => {
    const { colorPreset, contrast } = config;

    return {
        action: {
            active: neutral[500],
            disabled: alpha(neutral[900], 0.38),
            disabledBackground: alpha(neutral[900], 0.12),
            focus: alpha(neutral[900], 0.16),
            hover: alpha(neutral[900], 0.04),
            selected: alpha(neutral[900], 0.12)
        },
        background: {
            default: contrast === 'high' ? neutral[50] : common.white,
            paper: common.white
        },
        divider: '#F2F4F7',
        error,
        info,
        mode: 'light',
        neutral,
        primary: isHexColor(colorPreset) ? generatePalette(colorPreset) : getPrimary(colorPreset),
        success,
        text: {
            primary: neutral[900],
            secondary: neutral[500],
            disabled: alpha(neutral[900], 0.38)
        },
        warning
    };
};
