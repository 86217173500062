import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import XCircleIcon from '@untitled-ui/icons-react/build/esm/XCircle';
import { Avatar, Box, IconButton, OutlinedInput, Stack, SvgIcon, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/material/styles";

export const ChatMessageReply = (props) => {
    const { onCancel, replyMessageSender, replyMessageContent, ...other } = props;

    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const handleKeyUp = useCallback((event) => {
        if (event.code === 'Enter') {
            handleCancel();
        }
    }, [handleCancel]);

    const ellipsisThreshold = matchUpSm ? 120 : 60;

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
                mx: 2,
                px: 1,
                py: 0,
                pt: 1,
                justifyContent: 'space-between',
            }}
            borderLeft={"5px solid gray"}
            {...other}

        >
            <Stack direction="row" alignItems="flex-start" flexGrow={1}>
                <Typography variant="body2">
                    Replying to
                </Typography>
                <Stack pl={3} alignItems="flex-start" maxWidth="80%">
                    <Typography variant="body2" fontWeight={600} color="gray">
                        {replyMessageSender}
                    </Typography>
                    <Typography
                        variant="body2"
                        textAlign="left"
                    >
                        {replyMessageContent.length > ellipsisThreshold ? `${replyMessageContent.substring(0, ellipsisThreshold)}...` : replyMessageContent}
                    </Typography>
                </Stack>
            </Stack>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Tooltip title="Cancel Reply">
                    <Box sx={{ m: 1 }}>
                        <IconButton
                            color="gray"
                            onClick={handleCancel}
                            onKeyUp={handleKeyUp}
                        >
                            <SvgIcon>
                                <XCircleIcon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
        </Stack>
    );
};

ChatMessageReply.propTypes = {
    onCancel: PropTypes.func,
    replyMessageSender: PropTypes.string,
    replyMessageContent: PropTypes.string,
};
