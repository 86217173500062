import { useTheme } from '@mui/material/styles';

export const Logo = () => {
    const theme = useTheme();
    const fillColor = theme.palette.primary.main;

    return (
        <svg width="300px" height="300px" viewBox="0 0 300 300" version="1.1">
            <g id="surface1">
                <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }} d="M 142.96875 44.765625 C 120.9375 47.578125 97.5 59.765625 81.328125 77.34375 C 41.953125 119.765625 41.953125 180.234375 81.328125 222.65625 C 111.796875 255.703125 161.953125 265.546875 202.734375 246.328125 C 217.734375 239.53125 234.375 226.40625 234.375 221.71875 C 234.375 220.3125 227.109375 211.640625 217.96875 202.734375 L 201.5625 186.5625 L 195.234375 191.25 C 177.65625 204.375 162.890625 207.421875 143.671875 202.5 C 131.953125 199.453125 129.140625 199.453125 117.65625 202.734375 C 110.15625 205.078125 104.0625 205.78125 102.65625 204.375 C 101.484375 203.203125 102.65625 197.8125 105.46875 191.015625 C 110.15625 180.46875 110.15625 179.0625 106.640625 169.21875 C 99.84375 148.59375 103.359375 129.84375 117.65625 113.90625 C 134.0625 94.921875 160.078125 90 183.28125 101.484375 C 191.25 105.46875 196.640625 106.875 198.984375 105.46875 C 204.375 102.65625 229.6875 75.703125 229.6875 72.890625 C 229.6875 71.71875 226.171875 67.96875 222.1875 64.921875 C 199.6875 48.046875 172.96875 41.25 142.96875 44.765625 Z M 142.96875 44.765625 " />
                <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }} d="M 123.28125 144.140625 C 121.171875 146.25 119.53125 148.828125 119.53125 150 C 119.53125 153.515625 127.03125 159.609375 130.78125 158.90625 C 136.40625 157.734375 139.921875 150.703125 136.875 145.78125 C 133.359375 140.390625 127.96875 139.6875 123.28125 144.140625 Z M 123.28125 144.140625 " />
                <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }} d="M 152.34375 144.140625 C 146.25 151.40625 155.390625 163.359375 162.65625 157.265625 C 167.109375 153.515625 167.578125 147.421875 163.59375 143.4375 C 159.84375 139.6875 155.859375 139.921875 152.34375 144.140625 Z M 152.34375 144.140625 " />
                <path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }} d="M 181.875 144.140625 C 179.765625 146.25 178.125 148.828125 178.125 150 C 178.125 153.515625 185.625 159.609375 189.375 158.90625 C 195 157.734375 198.515625 150.703125 195.46875 145.78125 C 191.953125 140.390625 186.5625 139.6875 181.875 144.140625 Z M 181.875 144.140625 " />
            </g>
        </svg>
    );
};
