import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firebaseApp, analytics, db } from 'src/libs/firebase';
import { setUserProperties } from "firebase/analytics";
import { logAnalyticsEvent } from 'src/utils/logging';

const useSchool = () => {
    const [parentURL, setParentURL] = useState(null);
    const [schoolTemp, setSchoolTemp] = useState(null);
    const [school, setSchool] = useState(null);
    const location = useLocation();
    const slug = location.pathname.split("/").pop();

    const isIframe = window.self !== window.top;

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');

    useEffect(() => {
        const fetchSchool = async () => {

            try {
                const schoolRef = doc(db, 'schools', slug);
                const schoolSnap = await getDoc(schoolRef);
                if (schoolSnap.exists()) {
                    if (isIframe) {
                        setSchoolTemp({
                            id: schoolSnap.id,
                            ...schoolSnap.data(),
                        });
                    } else {
                        setSchool({
                            id: schoolSnap.id,
                            ...schoolSnap.data(),
                        });

                    }

                    if (analytics) {
                        setUserProperties(analytics, { school: schoolSnap.id });
                    }
                } else {
                    setSchoolTemp(null);
                    setSchool(null);
                    if (analytics) {
                        logAnalyticsEvent('school_not_found', { school: slug });
                    }
                    console.error('School not found', slug);
                }
            } catch (error) {
                setSchoolTemp(null);
                setSchool(null);
                if (analytics) {
                    logAnalyticsEvent('school_fetch_error', { school: slug, error });
                }
                console.error('Error fetching school', slug, error);
            }
        };

        fetchSchool();

        window.addEventListener('message', (event) => {
            if (event.data?.type === 'CT_TXI') {
                if (!event.data.url) {
                    if (analytics) {
                        logAnalyticsEvent('no_parent_url', { message: event.data });
                    }
                    return;
                }

                console.log('Parent URL', event.data.url);
                setParentURL(event.data.url);

                if (analytics) {
                    logAnalyticsEvent('parent_url_received', { url: event.data.url, schoolSlug: slug });
                }
            } else {
                if (analytics) {
                    logAnalyticsEvent('unknown_message', { message: event.data });
                }
            }
        });

        window.parent.postMessage({ type: 'CT_RXI' }, '*');
    }, [slug]);

    useEffect(() => {
        if (schoolTemp && parentURL) {
            const parentDomain = new URL(parentURL).hostname;
            const schoolDomain = schoolTemp.domain;

            if (parentDomain !== schoolDomain) {
                console.log("No domain found for ", parentDomain);
                if (analytics) {
                    logAnalyticsEvent('mismatched_domains', { parentDomain, schoolDomain });
                }

                // Nullify school if the parent domain does not match the school domain
                setSchoolTemp(null);
                setSchool(null);
            } else {
                setSchool(schoolTemp);
            }
        }
    }, [schoolTemp, parentURL]);

    return { school, slug, parentURL, isIframe, tab };
};

export default useSchool;